.MuiSelect-select:focus {
    background-color: transparent !important;
}
span.MuiTouchRipple-root > span {
    display: none !important;
}
.chart-range-filter{
    width: 450px !important;
    padding: 0 !important;
}
.chart-range-filter .MuiInputBase-input{
    padding: 3px 0 !important;
}
.MuiLinearProgress-barColorPrimary{
    background-color: #1c1e1e !important;
}
#deviceinfo-table tbody tr:nth-child(even) {
    background: #e7e8e8;
}
#deviceinfo-table tbody tr:nth-child(odd) {
    background: #cbcdcf;
}
.line-chart > div {
    height: 70px !important;
}
.pie-chart > div {
    height: 192px !important;
    padding: 0 !important;
}
.MuiTableCell-body {
    color: #002941;
    border: 2px solid #FFF;
}
.tableGrid {
    width: 100% !important;
    padding: 0 !important;
    position: relative !important;
}
.commentGrid {
    width: 30% !important;
    padding: 0 !important;
}
.MuiButton-root.icon-btn{
    min-width: 24px !important;
    margin-left: 10px;
}
.MuiButton-root.icon-btn .MuiButton-text{
    padding: 0 !important;
    min-width: 24px !important;
}
.MuiButton-startIcon{
    margin: 0 !important;
}
/* .MuiButton-iconSizeMedium > *:first-child{
    font-size: 16px;
} */
.comment-section{
    display: flex;
    align-items: flex-start;
    padding: 5px 10px;
    padding-top: 7px;
    border-top: 1px solid #d3d3d3;
}
.comment-section:first-child{
    border: 0 !important;
}
span.profile {
    width: 35px;
    display: inline-flex;
    border-radius: 50%;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}
span.profile img {
    width: 100%;
}
div.comment-info {
    font-size: 13px;
    padding: 0 5px 0 10px;
    width: calc(100% - 110px);
}
a {
    color: #277abe;
    text-decoration: none;
}
div.comment-info > span {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
span.time {
    font-size: 12px;
    text-align: right;
    display: inline-block;
    width: 75px;
}
.date-picker-area{
    display: flex;
    align-items: flex-start;
}
.date-picker-area.range-area{
    padding: 10px 15px;
    padding-top: 0;
}
.date-picker-area.range-area .picker{
    margin: 10px 0 !important;
}
.picker{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.picker > span{
    margin-right: 10px;
}
.date-picker-area .MuiInput-root {
    font-size: .9rem !important;
}
.date-picker-area .MuiTextField-root {
    border: 1px solid #0000006b !important;
    margin-right: 10px;
    padding: 3px 5px 3px 10px;
    border-radius: 5px;
}
.date-picker-area .MuiInput-underline:before,.date-picker-area .MuiInput-underline:after {
    border: 0 !important;
}
.date-picker-area .MuiIconButton-root {
    padding: 0 !important;
}
.box-chart, .box-chart > div[class*="Component-root"] {
    height: 100% !important;
    padding: 0 !important;
}
.box-area {
    margin-bottom: 15px !important;
}

ul[name="legend-bottom"] {
    flex-wrap: wrap;
}
ul[name="legend-bottom"] li{
    padding: 0;
    margin: 0;
    width: 33.3%;
}
ul[name="legend-bottom"] li > svg {
    width: 14px !important;
    height: 14px !important;
}
ul[name="legend-bottom"] li > div {
    padding: 0 !important;
    margin-left: 5px;
}
ul[name="legend-bottom"] li > div > span {
    font-size: 12px;
}

span.prev-circle::before {
    content: '';
    width: 10px;
    height: 10px;
    background: rgb(49, 208, 217);
    margin: 0 5px;
    border-radius: 50%;
    display: inline-block;
}
span.prev-circle.cl1::before {
    background: rgb(252, 192, 39);
}
span.prev-circle.cl2::before {
    background: rgb(91, 155, 213);
}
span.prev-circle.cl3::before {
    background: rgb(0, 229, 166);
}
span.prev-circle.cl4::before {
    background: rgb(220, 57, 18);
}
span.prev-circle.cl5::before {
    background: rgb(144, 144, 144);
}
.pie-chart-section {
    width: 200px;
    margin: 0 auto;
}
.pie-info {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
}
.chart-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
}
.chart-title h3 {
    margin: 0 !important;
}
.progress-bar{
    -webkit-animation:spin 1.5s linear infinite;
    -moz-animation:spin 1.5s linear infinite;
    animation:spin 1.5s linear infinite;
}
.progress-bar-slide {
    background-color: rgb(28, 30, 30);
    width: 0%;
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 12px;
    height: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 3px;
}
.progress-bar-slide.per-1 {
    width: 20%;
    padding: 2px 5px;
}
.progress-bar-slide.per-2 {
    width: 50%;
    padding: 2px 5px;
}
.progress-bar-slide.per-3 {
    width: 70%;
    padding: 2px 5px;
}
.progress-bar-slide.per-3 {
    width: 100%;
    padding: 2px 5px;
}
@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}
.raw-data{
    float: right;
    padding: 5px 10px !important;
    width: 100px;
    margin: 0 !important;
}
.raw-data svg {
    font-size: 14px !important;
    margin-right: 5px;
}
.filter-button{
    display: flex;
    flex-wrap: wrap;
    padding: 10px 15px;
    padding-bottom: 0;
}
.filter-button button:first-child {
    margin-left: 0 !important;
}
.filter-button button:last-child {
    margin-right: 0 !important;
}
.bar-chart {
    height: 240px;
}
.bar-chart > canvas {
    height: 100% !important;
}
.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.modal-header h3 {
    margin: 0;
}
.modal-header .MuiButton-text {
    padding: 0;
    min-width: 24px;
}
.chart-loader{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
}
.chart-loader > div {
    border:14px solid #343a40;
    border-top:14px solid #00999b;
    border-radius:50%;
    width:100px;
    height:100px;
    -webkit-animation:chartspin 2s linear infinite;
    animation: chartspin 2s linear infinite;
    margin: 20px auto;
  } 
@-webkit-keyframes chartspin {
    0%{
          -webkit-transform:rotate(0);
          transform:rotate(0)
    }
    100%{
        -webkit-transform:rotate(360deg);
        transform:rotate(360deg)
    }
}
@keyframes chartspin {
    0%{
        -webkit-transform:rotate(0);
        transform:rotate(0)
    }
    100%{
        -webkit-transform:rotate(360deg);
        transform:rotate(360deg)
    }
}
.large-chart {
    width: 40% !important;
    padding: 24% 0px 5px !important;
}
.MuiGridList-root{
    align-items: flex-start !important;
}