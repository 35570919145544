body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media only screen and (max-width: 600px) {
  #CollapsibleDivTxt {
    display: none;
  }
}

@media (max-width: 1600px) {
  #CollapsibleDivTxt {
    display: none;
  }
}

@media (max-width: 1650px) {
  #CollapsibleDiv {
    width: 70px;
  }
}
.shfitNoteClassMin{
  display: block;
}
.shfitNoteClass{
  display: none;
}
.shiftNoteCollapseOut{
  min-height: 30px;

}
.shiftNoteCollapseIn{
  height: 30px;
  overflow: hidden;
}